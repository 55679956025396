$gray-dark-100: #212529;
$gray-dark-200: #313533;
$gray-dark-300: #3b403d;
$gray-dark-400: #858d98;
$gray-dark-500: #adb5bd;
$gray-dark-600: #ced4da;
$gray-dark-700: #f6f6f6;
$gray-dark-800: #e9e9ef;
$gray-dark-900: #f8f9fa;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9e9ef;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #5156be;
$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #fd625e;
$orange: #f1734f;
$yellow: #ffbf53;
$green: #2ab57d;
$teal: #050505;
$cyan: #4ba6ef;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "pink": $pink,
  "light": $light,
  "dark": $dark,
);
//
// _header.scss
//
$sidebar-bg: #f8fafc;
$sidebar-menu-item-color: #545a6d;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #545a6d;
$sidebar-menu-item-hover-color: #5156be;
$sidebar-menu-item-active-color: #5156be;
$sidebar-border-color: #e9e9ef;
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;
$sidebar-width-sm: 160px;
$sidebar-dark-border-color: #010102;
// Vertical Sidebar - Dark
$sidebar-dark-bg: rgb(26, 29, 30); //2c313a
$sidebar-dark-menu-item-color: #99a4b1;
$sidebar-dark-menu-sub-item-color: #858d98;
$sidebar-dark-menu-item-icon-color: #858d98;
$sidebar-dark-menu-item-hover-color: #ffffff;
$sidebar-dark-menu-item-active-color: #ffffff;

// Topbar - Deafult Light
$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #555b6d;

// Topbar - Dark
$header-dark-bg: rgb(37, 40, 41);
$header-dark-item-color: #e9ecef;

// Topbar Search
$topbar-search-bg: #f3f3f9;

// Footer
$footer-height: 60px;
$footer-bg: #ffffff;
$footer-color: #74788d;

// Horizontal nav
$topnav-bg: #f8fafc;
$menu-item-color: #545a6d;
$menu-item-active-color: #5156be;

// Horizontal nav
$topnav-dark-bg: #363a38;
$menu-dark-item-color: #99a4b1;
$menu-dark-item-active-color: #fff;

// Right Sidebar
$rightbar-width: 300px;

// Display
$display-none: none;
$display-block: block;

// Brand
$navbar-brand-box-width: 250px;

// Boxed layout width
$boxed-layout-width: 1300px;
$boxed-body-bg: #f0f0f0;

// apex charts
$apex-grid-color: #f8f9fa;

// table
$table-head-bg: $gray-100;
$table-dark-border-color: tint-color($gray-800, 7.5%);

//
// Dark theme Layout
//

// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.8;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// fusv-disable
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);
$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900,
);

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900,
);

$purples: (
  "purple-100": $purple-200,
  "purple-200": $purple-100,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900,
);

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900,
);

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900,
);

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,
);

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900,
);

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900,
);

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900,
);

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900,
);
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Prefix for :root CSS variables

$variable-prefix: bs-;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));
// scss-docs-end variable-gradient

// Spacing
//
// Control th styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
);

// $negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
);

// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: #ffffff;
$body-color: $gray-700;
$body-text-align: null;

// Utilities maps
//
// Extends the default `$theme-colors` maps to help create our utilities.

// Come v6, we'll de-dupe these variables. Until then, for backward compatibility, we keep them to reassign.
// scss-docs-start utilities-colors
// $utilities-colors: $theme-colors-rgb;
// scss-docs-end utilities-colors

// scss-docs-start utilities-text-colors
// $utilities-text: map-merge(
//   $utilities-colors,
//   (
//     "black": to-rgb($black),
//     "white": to-rgb($white),
//     "body": to-rgb($body-color)
//   )
// );
// $utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");
// scss-docs-end utilities-text-colors

// scss-docs-start utilities-bg-colors
// $utilities-bg: map-merge(
//   $utilities-colors,
//   (
//     "black": to-rgb($black),
//     "white": to-rgb($white),
//     "body": to-rgb($body-bg)
//   )
// );
// $utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");
// scss-docs-end utilities-bg-colors

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: underline;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
// scss-docs-end grid-breakpoints

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
// scss-docs-end container-max-widths

// @include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-row-columns: 6;

$gutters: $spacers;

// Container padding

$container-padding-x: 25;
// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);

$border-color: #e9e9ef;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.25rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.2rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.25rem 0.75rem rgba(18, 38, 63, 0.08);
$box-shadow-lg: 0 0.5rem 1rem rgba($black, 0.1);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
// scss-docs-end box-shadow-variables

$component-active-color: $white;
$component-active-bg: $primary;

// scss-docs-start caret-variables
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
// scss-docs-end caret-variables

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease;
$transition-collapse-width: width 0.35s ease;
$header-bg: #f4f4f4;

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1299;
  // z-index: 1002;
  // background-color: transparent;
  // border-bottom: 1px solid $border-color;
}

.light-mode {
  background: $sidebar-bg;
}

.dark-mode {
  background: $sidebar-dark-bg;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  //height: $header-height;
  //padding: 0 $grid-gutter-width 0 0;

  .dropdown .show {
    &.header-item {
      background-color: $gray-100;
    }
  }
}

.navbar-brand-box {
  padding: 0 10px 0 24px;
  width: 280px;
  border-right: 1px solid #e9e9ef;
  box-shadow: 0 2px 2px #f8fafc;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark-brandbox {
  border-right: 1px solid $sidebar-dark-border-color !important;
  box-shadow: 0 2px 2px $sidebar-dark-bg !important;
}

.logo {
  line-height: 69px;
  color: inherit !important;

  .logo-sm {
    display: none;
  }
}

.logo-txt {
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
  margin-left: 5px;
}

.logo-light {
  display: none;
}

body:not([data-sidebar-size="sm"]) {
  #vertical-menu-btn {
    //margin-left: -52px;
    //margin-right: 20px;
    @media (max-width: 991.98px) {
      margin-left: 0;
    }
  }
}

.header-bg-white {
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  // background: $sidebar-bg;
}

.wealth-series-atlas, .wealth-series-titan, .wealth-series-everest, .wealth-series-lite, .release-notes {
  .header-bg-white {
    box-shadow: none;
  }

  #page-topbar {
    border-right: none;
  }
}

.header-height {
  height: $header-height;
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 40px} / 2) 0;
  position: relative;

  .form-control {
    border: none;
    height: 40px;
    padding-left: 17px;
    padding-right: 50px;
    background-color: $topbar-search-bg;
    box-shadow: none;
  }

  .btn {
    position: absolute;
    right: 3px;
    top: 3px;
    height: 34px;
    padding: 0 10px;
  }
}

.layout-mode-light {
  display: none;
}

body[data-layout-mode="dark"] {
  .layout-mode-dark {
    display: none;
  }

  .layout-mode-light {
    display: inline-block;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: "black";
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width})
    calc(#{$grid-gutter-width} / 2) $footer-height
    calc(#{$grid-gutter-width} / 2);
}

.header-item {
  //height: $header-height + 5;
  height: 66px;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0;
  padding: 0 !important;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-600;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.notification-item {
  .d-flex {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-100;
    }
  }
}

.vimeo-tutorial-iframe {
  width: 840px;
  height: 640px;
}

@media (max-width: 799px) {
  .vimeo-tutorial-iframe {
    width: 100%;
    height: 266px;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: $gray-100;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}

body[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-dark-bg;
    border-color: $gray-dark-300;
  }

  .navbar-brand-box {
    background-color: $header-dark-bg;
    border-color: $header-dark-bg;
    box-shadow: 0px 0px 2px $header-dark-bg;

    .logo {
      color: $white !important;
    }
  }

  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }

    &.border-start,
    &.border-end {
      border-color: rgba($white, 0.1) !important;
    }

    &.bg-soft-light {
      background-color: rgba($white, 0.04) !important;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }

    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] {
  .navbar-brand-box {
    width: auto;
    border: 0;
    background-color: transparent;
    box-shadow: none;

    @media (min-width: 992px) {
      padding-left: 0;
      margin-right: 10px;
    }
  }

  .page-content {
    margin-top: $header-height;
    padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }

  .navbar-header {
    @media (min-width: 992px) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }

  &[data-sidebar="dark"] {
    .navbar-brand-box {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 15px;
    }
  }
}

body[data-layout-mode="dark"] {
  .header-item {
    &.border-start,
    &.border-end {
      border-color: rgba($white, 0.1) !important;
    }

    &.bg-soft-light {
      background-color: rgba($white, 0.04) !important;
    }
  }

  #page-topbar {
    background-color: $header-dark-bg;
    border-color: $gray-dark-300;
  }

  .app-search {
    // Form Control
    .form-control {
      color: $gray-dark-500;
      background-color: lighten($gray-dark-200, 2%);
      border: 1px solid $gray-dark-300;
    }
  }

  .notification-item {
    .d-flex:hover {
      background-color: lighten($gray-dark-200, 4%);
    }
  }

  // Dropdown with Icons
  .dropdown-icon-item {
    color: $gray-dark-500;

    &:hover {
      background-color: $gray-dark-300;
    }
  }

  &[data-sidebar="light"] {
    @media (min-width: 992px) {
      &[data-topbar="light"] {
        .navbar-brand-box {
          .logo {
            color: $dark !important;
          }
        }

        #vertical-menu-btn {
          color: $dark;
        }
      }
    }
  }
}

body[data-layout-mode="dark"][data-topbar="light"] {
  .navbar-brand-box {
    background: $header-bg;
    border-right: none;
    box-shadow: none;

    .logo {
      color: $dark !important;
    }
  }

  .logo-light {
    display: none;
  }

  .logo-dark {
    display: block;
  }

  #vertical-menu-btn {
    color: $dark !important;
  }

  .header-item {
    color: $header-item-color;

    &:hover {
      color: $header-item-color;
    }

    &.border-start,
    &.border-end {
      border-color: rgba($dark, 0.1) !important;
    }

    &.bg-soft-light {
      background-color: rgba($dark, 0.04) !important;
    }
  }

  #page-topbar {
    background-color: $header-bg;
    border-color: $gray-300;
  }

  // Dropdown with Icons
  .dropdown-icon-item {
    color: $gray-500;

    &:hover {
      background-color: $gray-300;
    }
  }

  .app-search {
    // Form Control
    .form-control {
      color: $gray-500;
      background-color: lighten($gray-200, 2%);
      border: 1px solid $gray-300;
    }
  }

  .header-profile-user {
    background-color: $gray-600;
  }

  .noti-icon {
    i {
      color: $header-item-color;
    }
  }

  .notification-item {
    .d-flex {
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

body.dark {
  button.header-item svg path {
    fill: white;
  }
}
