.release-notes-page {
  font-family: Inter;
  padding: 0 24px;
  height: calc(100vh - 96px);
  background: transparent;
}

.scrollable-section {
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer/Edge */
}
.scrollable-section::-webkit-scrollbar {
  display: none; /* For Webkit browsers */
}

.release-notes-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: transparent;
  padding-bottom: 24px;
}

.release-notes-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.25px;
}

.release-notes-subtitle {
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.release-notes-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
  padding-bottom: 24px;
  height: calc(100% - 107px);
}

.release-notes-left {
  width: calc((100% / 3 * 2) - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  height: 100%;
}

.change-log {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.change-log-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.change-log-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
  width: 100%;
}

.change-log-date {
  color: rgba(0, 0, 0, 0.60);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  width: 100%;
}

.change-log-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.change-log-tag {
  padding: 3px 10px;
  color: #1269EC;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  cursor: pointer;
  border-radius: 100px;
  background: rgba(187, 226, 255, 0.20);
}

.change-log-markdown {
  color: rgba(0, 0, 0, 0.87);
  background: transparent !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  width: 100%;

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

   li+li {
    margin-top: .25em;
  }
}

.release-notes-right {
  width: calc(100% / 3 - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  height: 100%;
}

.change-log-nav {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 16px;
  width: 100%;
  text-decoration: none !important;

  &.active-nav {
    border-radius: 4px;
    background: rgba(187, 226, 255, 0.20);
  }
}

.change-log-nav-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.change-log-nav-date {
  color: rgba(0, 0, 0, 0.60);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.13px;
}

.release-note-dialog-title {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  letter-spacing: 0.15px !important;
}

.release-note-dialog-subtitle {
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
  padding: 0 16px;
  margin-bottom: 8px;
}

.release-note-dialog-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;

  ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-bottom: 0;

    li {
      margin-bottom: 8px;
    }
  }
}

.release-note-dialog-actions {
  justify-content: center !important;
  padding: 0 16px 16px 16px !important;
}

.release-note-dialog-button {
  padding: 8px 24px !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 123, 255, 0.50) !important;
  color: #007BFF !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: initial !important;
}

.release-notes-modal {
  .MuiDialog-paper {
    max-width: 460px !important;
    border-radius: 8px !important;
  }
}

body.dark {
  .release-notes-header {
    background-color: #3c3c3f;
  }

  .release-notes-title {
    color: #fff;
  }

  .release-notes-subtitle {
    color: rgba(255, 255, 255, 0.7);
  }

  .change-log-title {
    color: #fff;
  }

  .change-log-date {
    color: rgba(255, 255, 255, 0.7);
  }

  .change-log-tag {
    background: rgba(31, 71, 205, 0.20);
    color: #008FFF;
  }

  .change-log-markdown {
    background-color: #3c3c3f;
    color: #fff;
  }

  .change-log-nav {
    &.active-nav {
      background: rgba(0, 158, 255, 0.16);
    }
  }

  .change-log-nav-title {
    color: #fff;
  }

  .change-log-nav-date {
    color: rgba(255, 255, 255, 0.7);
  }

  .release-note-dialog-title {
    color: #fff !important;
  }

  .release-note-dialog-subtitle {
    color: rgba(255, 255, 255, 0.7);
  }

  .release-note-dialog-content {
    color: #fff;
  }

  .release-note-dialog-button {
    border: 1px solid #009EFF !important;
    color: #009EFF !important;
  }
}

@media (max-width: 1100px) {
  .release-notes-left {
    width: 100%;
  }
  
  .release-notes-right {
    display: none;
  }
}